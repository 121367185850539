import React, { useEffect, useState } from 'react';
import HomeServices from '../../services/HomeServices';
import NAImg from "../../Assets/Images/na.jpeg"
import Slider from 'react-slick';
const HomeBanner = ({...props}) => {
    const [loading, setLoading] = useState(false); // Loading state
    const [dataBanner, setDataBanner] = useState(Array.from({length: 2}, (v, i) => i) );

    const fetchDatabanner = async () => {
        setLoading(true); // Set loading to true before fetching data
        try {
          const response = await HomeServices?.getHomeBanner();
          const data = response.data.data;
          setDataBanner(data);
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error gracefully, maybe set an error state to display to the user
        }
    };
    

    useEffect(() => {
        fetchDatabanner();
    }, []);

    var settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToScroll: 1,
        arrows: false,
        slidesToShow: 1, // Default number of slides to show
        initialSlide: 0,
    }

    return (
        <React.Fragment>
            <div className="container banner-slick">
                <Slider {...settings}>
                    {
                        dataBanner?.map((e,i)=>{
                            return <div className={`banner-slick-item `} key={Math.random()}>
                                <div className='home-page-banner' style={{"backgroundImage":`url(${e?.banner_img})`}}>
                                    <img 
                                        src={e?.banner_img} 
                                        className="d-block w-100"  
                                        alt={e?.banner_img}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src=NAImg;
                                        }}                                            
                                    />
                                </div>
                            </div>
                        })
                    }
                </Slider>
            </div>
        </React.Fragment>
    )
}

export default HomeBanner
