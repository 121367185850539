import React from 'react'
import Group from "../../Assets/Images/Group.png";
const style = {
    width: `175px`,
}

const HomeSection4 = () => {
    return (
        <section className='container home-section-4'>
            <div className='d-flex justify-content-center my-5'>
                <label htmlFor="langauge-select" className='d-flex align-items-center home-select'>
                    <div>
                        <img src={Group} alt="Group" width={25}/>
                    </div>
                    <div>
                        <select id="langauge-select" className='form-control' style={style}>
                            <option>English (India)</option>
                            <option>English (U.S.)</option>
                            <option>English (England)</option>
                        </select>
                    </div>
                </label>
            </div>
        </section>
    )
}

export default HomeSection4
