import axios from "axios";
import * as types from './actionTypes';
import HomeServices from "../../services/HomeServices";

const userAuthentication = (payload) => (dispatch) => {
   
    dispatch({ type: types.USER_LOGIN_REQUEST });
    return HomeServices?.signIn(payload).then((res) => {
        // console.log(res.data)
        return dispatch({ type: types.USER_LOGIN_SUCCESS, payload: res.data });
        
    }).catch((err) => {
        return dispatch({ type: types.USER_LOGIN_FAILURE, payload: err });
    })
}




const userSignout = () => (dispatch) => {
    return dispatch({ type: types.USER_SIGNOUT_SUCCESS });
}

export { userAuthentication, userSignout}

