import Cookies from 'js-cookie';
import * as types from "./actionTypes";

const initialState = {
    isAuth: Cookies.get('isAuth') || false,
    isAdmin: Cookies.get('isUserAdmin') || false,
    token: Cookies.get('token') || '',
    isLoading: false,
    isError: false,
    message: '',
    user: Cookies.get('user') || [],
    users: [],
}

const reducer = (oldState = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        
        case types.USER_LOGIN_REQUEST:
            return { ...oldState, isLoading: true };

        case types.USER_LOGIN_SUCCESS:
           
           
            Cookies.set('user_name', payload.data.username);
            Cookies.set('token', payload.data.token);
            Cookies.set('user_type_id', payload.data.user_type_id);

            return {
                ...oldState,
                isLoading: false,
                isAuth: payload.isAuth,
                isAdmin: payload.isAdmin,
                token:payload.data.token,
                user: payload.data.user_name,
                is_rules_displayed: payload.is_rules_displayed,
                passChange: payload.passChange,
                ruleType: payload.ruleType,
                user_type_id: payload.user_type_id,
            };

        case types.USER_LOGIN_FAILURE:
            Cookies.remove('isAuth');
            Cookies.remove('user_name');
            Cookies.remove('token');
            Cookies.remove('is_rules_displayed');
            Cookies.remove('passChange');
            Cookies.remove('ruleType');
            Cookies.remove('user_type_id');

            return {
                ...oldState,
                isLoading: false,
                isError: true,
                isAuth: false,
                isAdmin: false,
                token: '',
                user: [],
                is_rules_displayed: '',
                passChange: '',
                ruleType: null,
                user_type_id: '',
            };

        case types.USER_SIGNOUT_SUCCESS:
            Cookies.remove('isAuth');
            Cookies.remove('user_name');
            Cookies.remove('token');
            Cookies.remove('is_rules_displayed');
            Cookies.remove('passChange');
            Cookies.remove('ruleType');
            Cookies.remove('user_type_id');

            return {
                isAuth: false,
                isLoading: false,
                isError: false,
                isAdmin: false,
                user: [],
                token: '',
                is_rules_displayed: '',
                passChange: '',
                ruleType: null,
                user_type_id: '',
            };

        default:
            return oldState;
    }
};

export { reducer };
