import { Link } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import menu1 from "../../Assets/Images/Icons/menu1.png";
import menu5 from "../../Assets/Images/Icons/menu5.png";
import menu6 from "../../Assets/Images/Icons/menu6.png";
import cIcon1 from "../../Assets/Images/Icons/Roulette_Icons.png";
import cIcon2 from "../../Assets/Images/Icons/Baccarat_Icons.png";
import cIcon3 from "../../Assets/Images/Icons/Andar_bahar_Icons.png";
import cIcon4 from "../../Assets/Images/Icons/Poker_Icons.png";
// import cIcon5 from "../../Assets/Images/Icons/Blackjack_icons.png";
import cIcon6 from "../../Assets/Images/Icons/casino.png";
// import cIcon7 from "../../Assets/Images/Icons/seven.png";
import cIcon8 from "../../Assets/Images/Icons/dices.png";
import cIcon9 from "../../Assets/Images/Icons/tg.png";
import cIcon10 from "../../Assets/Images/Icons/Blackjack_icons.png";
import cIcon11 from "../../Assets/Images/Icons/Andar_bahar_Icons.png";
import cIcon12 from "../../Assets/Images/Icons/asas.png";
import HomeServices from '../../services/HomeServices';

const TopCategories = () => {
    const [activeTab, setActiveTab] = useState("Roulette"); // Initial active tab
    const [loading, setLoading] = useState(false); // Loading state
    const [Data, setData] = useState([]);
    const [params, setParams] = useState({
        limit: 1000,
        category: "roulette",
    });
    
      const fetchData = async () => {
        setLoading(true); // Set loading to true before fetching data
        try {
          const response = await HomeServices?.getCasinoListByCategory(params);
          const data = response?.data || [];
          setData(data.data);
        } catch (error) {
          console.error("Error fetching data:", error);
          // Handle error gracefully, maybe set an error state to display to the user
        } finally {
          setLoading(false); // Set loading to false after fetching data
        }
    };
    
    const handleButtonClick = (category, limit) => {
        setActiveTab(category);
        setParams({
          limit: limit,
          category: category,
        });
    };

    useEffect(() => {
        fetchData();
      }, []);

    return (
        <section className="TopCategories">
            <ul className="nav nav-tabs" role="tablist">
                <li 
                    className="nav-item"
                    role="presentation"
                    onClick={() => handleButtonClick(`Aviator`, 1000)}
                >
                    <button
                        type="button"
                        id={`uncontrolled-tab-example-tab-Aviator`}
                        role="tab"
                        data-rr-ui-event-key={`Aviator`}
                        aria-controls={`uncontrolled-tab-example-tabpane-Aviator`}
                        aria-selected={activeTab === `Aviator`}
                        className={`nav-link ${
                            activeTab === `Aviator` ? "active" : ""
                        }`}
                        tabIndex={-1}
                    >
                        <img width={"34px"} src={menu6} alt="Aviator" />
                        <span className="text-white fs-6">Aviator</span>
                    </button>
                </li>
                {[
                    { category: "Roulette", icon: cIcon1, label: "Roulette" },
                    { category: "baccarat", icon: cIcon2, label: "Baccarat" },
                    { category: "blackjack", icon: cIcon10, label: "Black Jack" },
                    { category: "andar-bahar", icon: cIcon3, label: "Andar Bahar" },
                    { category: "poker", icon: cIcon4, label: "Poker" },
                    { category: "teen-patti", icon: cIcon6, label: "32 Card" },
                    { category: "lottery", icon: cIcon8, label: "Lottery" },
                    { category: "virtual", icon: menu5, label: "Virtual Sports" },
                    { category: "dragon-tiger", icon: cIcon9, label: "Dragon Tiger" },
                    { category: "table-games", icon: cIcon11, label: "Table Games" },
                    { category: "holdem", icon: cIcon12, label: "Holdem" },
                ].map(({ category, icon, label }) => (
                    <li
                        key={category}
                        className="nav-item"
                        role="presentation"
                        onClick={() => handleButtonClick(category, 1000)}
                    >
                        <button
                            type="button"
                            id={`uncontrolled-tab-example-tab-${category}`}
                            role="tab"
                            data-rr-ui-event-key={category}
                            aria-controls={`uncontrolled-tab-example-tabpane-${category}`}
                            aria-selected={activeTab === category}
                            className={`nav-link ${
                                activeTab === category ? "active" : ""
                            }`}
                            tabIndex={-1}
                        >
                            <img src={icon} alt={label} />
                            {label}
                        </button>
                    </li>
                ))}
                <li 
                    className="nav-item"
                    role="presentation"
                    onClick={() => handleButtonClick(`Matka`, 1000)}
                >
                    <button
                        type="button"
                        id={`uncontrolled-tab-example-tab-Matka`}
                        role="tab"
                        data-rr-ui-event-key={`Matka`}
                        aria-controls={`uncontrolled-tab-example-tabpane-Matka`}
                        aria-selected={activeTab === `Matka`}
                        className={`nav-link ${
                            activeTab === `Matka` ? "active" : ""
                        }`}
                        tabIndex={-1}
                    >
                        <img width={"34px"} src={menu1} alt="Matka" />
                        <span className="text-white fs-6">Matka</span>
                    </button>
                </li>
            </ul>
            <div className="tab-content">
                <div
                    role="tabpanel"
                    id={`uncontrolled-tab-example-tabpane-${activeTab}`}
                    aria-labelledby={`uncontrolled-tab-example-tab-${activeTab}`}
                    className="fade tab-pane active show"
                >
                    <div className="Live-Casino">
                        <div className="container-lg">
                            {loading ? (
                            <div>Loading...</div> // Display loading indicator
                            ) : (
                            <div className="listings">
                                <div className="row">
                                {Data &&
                                    Data.map((item, index) => (
                                    <div key={index} className="col-6 col-md-3 mb-4">
                                        <Link
                                        to={`/casinogame/${item.game_id}/${item.provider_name}`}
                                        >
                                        <div className="shadow rounded position-relative">
                                            <img
                                            src={item.Url}
                                            className="img-fluid w-100"
                                            alt=""
                                            loading="lazy"
                                            />
                                        </div>
                                        </Link>
                                    </div>
                                    ))}
                                </div>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TopCategories
