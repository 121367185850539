
import HttpServicesV1 from './HttpServicesV1';
const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const getHomeBanner = async () => {
    const url = `${REACT_APP_API_URL}/get/banner_list`;
    const response = await HttpServicesV1.get(
        url,
        { domain_id: 16 }
    );
    return response;
};

const getCasinoListByCategory = async (params) => {
    const url = `${REACT_APP_API_URL}/getCasinoListByCategory`;
    const response = await HttpServicesV1.post(
        url,
        params
    );
    return response;
};

const signUp = async (params) => {
    const url = `${REACT_APP_API_URL}/post/registration`;
    const response = await HttpServicesV1.post(
        url,
        params
    );
    return response;
};

const signIn = async (params) => {
    const url = `${REACT_APP_API_URL}/post/login`;
    const response = await HttpServicesV1.post(
        url,
        params
    );
    return response;
};

const generateLobbyURL = async () => {
    const url = `${REACT_APP_API_URL}/post/generateLobbyURL`;
    const response = await HttpServicesV1.post(url);
    return response;
};

const getWalletBalance = async () => {
    const url = `${REACT_APP_API_URL}/post/getWalletBalance`;
    const response = await HttpServicesV1.post(url);
    return response;
};

const updatePassword = async (params={}) => {
    const url = `${REACT_APP_API_URL}/post/update_password`;
    const response = await HttpServicesV1.post(url,params);
    return response;
};


const HomeServices = {
    getHomeBanner,
    getCasinoListByCategory,
    signUp,
    signIn,
    generateLobbyURL,
    getWalletBalance,
    updatePassword,
};

export default HomeServices;