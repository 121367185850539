import Header from "./Components/Header";
import { AllRoutes } from "./AllRoutes/AllRoute";
import Footer from "./Components/Footer";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Matka from "./Components/Matka/Matka";
import HeaderV2 from "./Components/HeaderV2";
import FooterV2 from "./Components/FooterV2";

function App() {
  const { pathname } = useLocation();
  const location=useLocation()
  function ScrollToTop() {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  }

  const hideNavbar = location.pathname.startsWith("/casinogame/") || location.pathname === "/aviator";




  return (
    <div>
      <ScrollToTop />
      <HeaderV2 />
      <AllRoutes />
      {!hideNavbar && 
        <FooterV2 />
      }
      <ToastContainer />
   
    </div>
  );
}

export default App;
