import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { FaCircleChevronLeft } from "react-icons/fa6";
import { data } from "jquery";
import { FaRegCalendar } from "react-icons/fa";
import { IoSearchOutline } from "react-icons/io5";
import wallet from '../../Assets/Images/Icons/wallet.png'
function BetHistoryPage() {
  // https://xplay24.com/api/v5/wallet-balance

  const [Data, setData] = useState([]);
  const [from_date, setFromDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
  );
  const [to_date, setToDate] = useState(new Date());
  const { token } = useSelector((store) => store.AuthReducer);

  const fetchData = async (fromDate, toDate) => {
    try {
      const fromDateObj = new Date(fromDate);
      const toDateObj = new Date(toDate);

      const fromDateInSeconds = Math.floor(fromDateObj.getTime() / 1000);
      const toDateInSeconds = Math.floor(toDateObj.getTime() / 1000);

      const response = await axios.post(
        "https://vbet002.com/api/v5/my-bet-list",
        {
          from_date: fromDateInSeconds,
          to_date: toDateInSeconds,
          limit: 15,
          betType: "C",
          match_id: "0",
          market_id: "0",
          sport_id: "0",
          pageno: 1,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]); // Update state to indicate an error occurred
    }
  };

  console.log(Data);
  useEffect(() => {
    fetchData(from_date, to_date);
  }, [from_date, to_date]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <main className="main">
        <div className="container">
          <div className="PagesHeading">
            <h2>My Bets</h2>
            <Link to="/">
            <div className="back-link pe-1">
              Back <FaCircleChevronLeft size={20} />
            </div>
            </Link>
          </div>
          <div className="payment-info my-4">
            <div className="row">
              <div className="col-6">
                <div className="balance mb-3">
                  <img src={wallet} alt="" />
                  Balance</div>
              </div>
              <div className="col-6">
                <div className="balance-amount mb-3"> 0</div>
              </div>
              <div className="col-6">
                <Link to="/withdraw"
                  className="btn btn-outline-secondary w-100"
                >
                  Withdraw
                </Link>
              </div>
              <div className="col-6">
                <Link to="/deposit" className="btn btn-primary w-100">
                  Deposit
                </Link>
              </div>
            </div>
          </div>

          <form action="" className="filter-form g-3 px-3 row mb-3">
            <div className="col-lg-6 col-md-3 col-6">
              <div className="input-group">
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={from_date}
                  onChange={(e) => setFromDate(e.target.value)}
                />
                <button
                  type="button"
                  className="calender-icon pointer-none btn btn-primary"
                >
                  <FaRegCalendar />
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-3 col-6">
              <div className="input-group">
                <input
                  type="date"
                  className="form-control"
                  name="date"
                  value={to_date}
                  onChange={(e) => setToDate(e.target.value)}
                />
                <button
                  type="button"
                  className="calender-icon pointer-none btn btn-primary"
                >
                  <FaRegCalendar />
                </button>
              </div>
            </div>
            <div className="col-lg-2 d-none col-md-3 col-6 btn-box">
              <button
                type="submit"
                className="btn btn-warning"
                onClick={() => fetchData(from_date, to_date)}
              >
                Load Report
              </button>
            </div>
            <div className="col-12">
              <div className="input-group">
                <input
                  placeholder="Search"
                  type="search"
                  className="form-control"
                />
                <button type="button" className="calender-icon btn btn-primary">
                  <IoSearchOutline />
                </button>
              </div>
            </div>
          </form>
          <div className="table-responsive px-3">
            <table className="table statement-table table-bordered w-100">
              <thead>
                <tr>
                  <th>Description</th>
                  <th width="160px">Market</th>
                  <th width="160px">Odds</th>
                  <th width="160px">Amount</th>
                 
                </tr>
              </thead>
              <tbody>
                {Data &&
                  Data.map((item, index) => (
                    <tr>
                      <td className="text-nowrap">
                        <span> {item.seriesName}</span>
                        <span className="d-block">{item.matchName}</span>
                        <span className="d-block">
                          {" "}
                          {new Date(item.Placed * 1000).toLocaleString(
                            "en-US",
                            {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            }
                          )}
                        </span>
                      </td>
                      <td>
                        {" "}
                        {item.marketName}
                        <span className="d-flex justify-content-end fw-bold">
                          {item.Type}
                        </span>
                      </td>
                      <td>{item.Odds}</td>
                      <td>{item.Stack}</td>

                     
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </>
  );
}

export default BetHistoryPage;
